import { useGetCurrentUserRoleQuery } from '@local/workspaces/dist/apiClients/enhancedWorkspaceMiddleware';
import { User } from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import CancelIcon from '@mui/icons-material/Cancel';
import { Chip, Divider, Grid, Stack, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Skeleton from '@mui/material/Skeleton';
import { useParams } from 'react-router-dom';

import { useObjectFilterParams } from 'src/hooks/useObjectFilterParams';
import { UNKNOWN_USERNAME, CLEAR_ALL } from 'src/strings';
import { dateRangeToString } from 'src/utils/filtering';

const Pill = ({ label, onDelete }: { label: string; onDelete?: (event?: any) => void }) => (
    <Chip
        automation-id="go-filter-pill"
        label={label}
        key={label}
        variant="outlined"
        onDelete={onDelete}
        deleteIcon={<CancelIcon />}
    />
);

const Loading = () => (
    <>
        <Skeleton variant="rectangular" width="110px" height="26px" />
        <Skeleton variant="rectangular" width="110px" height="26px" />
        <Skeleton variant="rectangular" width="110px" height="26px" />
    </>
);

interface ObjectFilterPillsProps {
    userData: User[];
    isLoading: boolean;
}

export function ObjectFilterPills({ userData, isLoading }: ObjectFilterPillsProps) {
    const {
        typeParams,
        removeTypeParam,
        updatedByParams,
        removeUpdatedOnParam,
        updatedOnParams,
        clearAllParams,
        removeUpdatedByParam,
    } = useObjectFilterParams();

    const theme = useTheme();
    const params = useParams();

    const { data: currentUserRoleData } = useGetCurrentUserRoleQuery({
        hubUrl: getHubUrlForCurrentOrg(),
        orgId: getOrgUuidFromParams(params),
        workspaceId: getSelectedWorkspaceFromParams(params),
    });

    const getUserName = (user_id: string) =>
        currentUserRoleData?.user_id === user_id
            ? `${userData.find((user) => user.user_id === user_id)?.full_name} (you)`
            : userData.find((user) => user.user_id === user_id)?.full_name || UNKNOWN_USERNAME;

    const validDateRange = updatedOnParams.startDate && updatedOnParams.endDate;
    const numFiltersApplied = (validDateRange ? 1 : 0) + updatedByParams.length + typeParams.length;

    return (
        <Collapse in={numFiltersApplied > 0}>
            <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                sx={{ paddingTop: '16px' }}
            >
                <Grid container direction="row" gap={1} justifyContent="flex-end">
                    {typeParams.map((value) => (
                        <Pill
                            key={value}
                            label={`${value}`}
                            onDelete={() => {
                                removeTypeParam(value);
                            }}
                        />
                    ))}
                    {isLoading && <Loading />}
                    {!isLoading &&
                        updatedByParams.map((user_id) => (
                            <Pill
                                key={user_id}
                                label={`${getUserName(user_id)}`}
                                onDelete={() => {
                                    removeUpdatedByParam(user_id);
                                }}
                            />
                        ))}
                    {validDateRange && (
                        <Pill
                            label={dateRangeToString(updatedOnParams)}
                            onDelete={removeUpdatedOnParam}
                        />
                    )}
                    <Divider orientation="vertical" flexItem />
                    <Button
                        onClick={clearAllParams}
                        size="small"
                        variant="text"
                        automation-id="go-filter-clear-all-button"
                        sx={{
                            padding: 0,
                            minWidth: '68px',
                            color: theme.palette.text.primary,
                        }}
                    >
                        {CLEAR_ALL}
                    </Button>
                </Grid>
            </Stack>
        </Collapse>
    );
}

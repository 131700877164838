import {
    ListedObject,
    useListObjectsQuery,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { ErrorScreen } from '@local/svgs/dist/pageState/ErrorScreen';
import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import EmptyState from '@local/web-design-system-2/dist/components/EmptyState/EmptyState';
import {
    HeadCell,
    SortedList,
} from '@local/web-design-system-2/dist/components/SortedList/SortedList';
import TableSkeleton from '@local/web-design-system-2/dist/components/TableSkeleton/TableSkeleton';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import {
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useObjectFilterParams } from 'src/hooks/useObjectFilterParams';
import { useObjectSearchParams } from 'src/hooks/useObjectSearchParams';
import { useObjectSortParams } from 'src/hooks/useObjectSortParams';
import { usePagination } from 'src/hooks/usePagination';
import { workspaceContentModeSelector } from 'src/store/features/displaySettingSlice';
import { useAppSelector } from 'src/store/store';
import {
    NETWORK_ERROR_DESCR,
    NETWORK_ERROR_TITLE,
    WORKSPACE_CONTENT_NO_CONTENT,
    WORKSPACE_CONTENT_NO_CONTENT_DESC,
} from 'src/strings';
import { FieldDefinition, Order } from 'src/types/fieldDefinition';
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'src/utils/pagination';

import { ContentOptions } from '../ContentOptions';
import { objectDefinition } from './FieldRowDefinitions';
import { ObjectRow } from './ObjectRow';
import { ListedObjectDisplay, processGooseContents } from './utils';

const useStyles = makeStyles()(() => ({
    pagination: {
        padding: 0,
    },
}));

interface ObjectListProps {
    objects: ListedObject[];
    isLoading: boolean;
    handleSort: (values: { key: string; order: Order }) => void;
    fields?: FieldDefinition[];
    isFiltering: boolean;
}

export function ObjectList(props: ObjectListProps) {
    const { isLoading, objects, handleSort, isFiltering, fields = objectDefinition } = props;

    // extra header needed for ellipsis menu
    const headCells: HeadCell<ListedObjectDisplay>[] = [
        ...fields.map((field) => ({
            id: field.key as keyof ListedObjectDisplay,
            label: field.label,
            sortable: !!field.sortFunction,
        })),
        {
            id: 'extraHeader' as keyof ListedObjectDisplay,
            label: '',
            sortable: false,
        },
    ];

    if (isLoading) {
        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headCells.map((cell) => (
                                <TableCell key={cell.id.toString()}>{cell.label}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableSkeleton
                            rows={5}
                            columns={4}
                            skeletonSx={{
                                height: '24px',
                            }}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    if (!isLoading && !objects.length) {
        return (
            <EmptyState
                title={WORKSPACE_CONTENT_NO_CONTENT}
                message={WORKSPACE_CONTENT_NO_CONTENT_DESC}
                image={
                    <Grid
                        sx={{
                            margin: 'auto',
                            width: '50%',
                        }}
                    >
                        <NotFoundSvg />
                    </Grid>
                }
            />
        );
    }

    const processedObjects = processGooseContents(0, objects);

    const renderRow = (object: ListedObjectDisplay) => (
        <ObjectRow key={object.created_at} object={object} />
    );

    return (
        <SortedList
            data={processedObjects}
            headCells={headCells}
            renderRow={renderRow}
            isLoading={isLoading}
            pagination={false} // pagination controls handled elsewhere on the page
            EmptyComponent={
                <EmptyState
                    title={WORKSPACE_CONTENT_NO_CONTENT}
                    message={WORKSPACE_CONTENT_NO_CONTENT_DESC}
                    image={<NotFoundSvg />}
                />
            }
            LoadingComponent={
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map((cell) => (
                                    <TableCell key={cell.id.toString()}>{cell.label}</TableCell>
                                ))}
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableSkeleton
                                rows={5}
                                columns={4}
                                skeletonSx={{
                                    height: '24px',
                                }}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            onSortChange={(key, newOrder) => {
                handleSort({
                    key: key as string,
                    order: newOrder === 'asc' ? Order.ASCENDING : Order.DESCENDING,
                });
            }}
            defaultOrderBy={
                fields.find((f) => f.defaultSortSettings)?.key as keyof ListedObjectDisplay
            }
            defaultOrder={
                fields.find((f) => f.defaultSortSettings)?.defaultSortSettings?.order ===
                Order.ASCENDING
                    ? 'asc'
                    : 'desc'
            }
            // Disgusting, but i don't have time for CSS.
            containerSx={{ height: `calc(100vh - ${isFiltering ? '360px' : '320px'})` }}
        />
    );
}

export const WorkspaceContent = () => {
    const { classes } = useStyles();
    const { evouiAffinityFolders } = useFlags();
    const workspaceContentMode = useAppSelector(workspaceContentModeSelector);
    const showFilterDropdown = evouiAffinityFolders && workspaceContentMode === 'path';
    const { page, pageSize, handleSetPage, handlePageSizeChange, setPaginationParams } =
        usePagination();

    const params = useParams();
    const sortFromHook = useObjectSortParams();
    const { filters: filtersFromHook } = useObjectFilterParams();
    const filters = useMemo(
        () => (showFilterDropdown ? filtersFromHook : {}),
        [showFilterDropdown, filtersFromHook],
    );
    const { updateObjectSortParams, orderBy, order, key } = useMemo(
        () =>
            showFilterDropdown
                ? sortFromHook
                : {
                      updateObjectSortParams: () => {},
                      orderBy: undefined,
                      order: undefined,
                      key: undefined,
                  },
        [showFilterDropdown, sortFromHook],
    );

    const { objectName } = useObjectSearchParams();

    const fields = objectDefinition.map((field) =>
        field.key === key ? { ...field, defaultSortSettings: { order: order as Order } } : field,
    );

    const handleSort = (values: { key: string; order: Order }) => {
        if (showFilterDropdown) {
            if (order === values.order && key === values.key) {
                return;
            }

            updateObjectSortParams(values);
            setPaginationParams((currentParams) => ({ ...currentParams, page: 0 }));
        }
    };

    useEffect(() => {
        setPaginationParams((currentParams) => ({ ...currentParams, page: 0 }));
    }, [filters, objectName]);

    const {
        data: gooseData,
        isFetching,
        isError,
    } = useListObjectsQuery({
        orgId: getOrgUuidFromParams(params),
        workspaceId: getSelectedWorkspaceFromParams(params),
        ...filters,
        objectName,
        orderBy,
        offset: page * pageSize,
        limit: pageSize,
    });

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        handleSetPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        if (!Number.isNaN(newPageSize)) {
            handlePageSizeChange(newPageSize);
        }
    };

    if (isError) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    return (
        <Stack>
            <ContentOptions
                paginationControls={
                    <Grid item>
                        <TablePagination
                            component="div"
                            count={gooseData?.total ?? 0}
                            page={page}
                            labelRowsPerPage="Show"
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            onPageChange={handleChangePage}
                            rowsPerPage={pageSize}
                            rowsPerPageOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                            classes={{ toolbar: classes.pagination }}
                        />
                    </Grid>
                }
            />
            <ObjectList
                objects={gooseData?.objects ?? []}
                isLoading={isFetching}
                handleSort={handleSort}
                fields={fields}
                isFiltering={Boolean(filtersFromHook?.schemaId || filtersFromHook?.author)}
            />
        </Stack>
    );
};

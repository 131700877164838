import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    base: {
        cursor: 'default',
        bottom: '24px',
        right: '24px',
        width: '338px',
        backgroundColor: '#1d1f23',
        color: 'white',
        borderRadius: '8px',
        padding: '16px',
        gap: '16px',
        pointerEvents: 'auto',
        boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px`,
    },
    detailsBase: {
        backgroundColor: '#282a2e',
        boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`,
        borderRadius: '8px',
        padding: '16px',
    },
    closeButton: {
        width: '24px',
        height: '24px',
        backgroundColor: '#4a4a4c',
        borderRadius: '50% !important',
        padding: '8px',
        boxShadow: '0px 4px 8px 0px #00000029',
    },
    webvizButton: {
        color: 'white',
        backgroundColor: '#348ed1',
        borderRadius: '4px !important',
    },
    zoomButton: {
        backgroundColor: '#393b3f',
        borderRadius: '4px !important',
        boxShadow:
            '0px 0px 0px 0.5px #1A1A1A, 0px 1px 4px 0px #1A1A1A7A, 0px 1px 1px 0px #FFFFFF40 inset !important',

        '&:hover': {
            backgroundColor: '#4a4c4f',
        },

        '&:active': {
            backgroundColor: '#012137',
        },
    },
    zoomButtonText: {
        color: 'white',
        fontFamily: 'Inter',
        fontSize: '13px',
        fontWeight: 400,
    },
    divider: {
        borderColor: '#4a4a4c',
        borderWidth: '1px',
    },
    descriptionContainer: {
        padding: '12px',
        backgroundColor: '#1d1f23',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: `0 0 10px 0 rgba(0,0,0,0.45) inset`,
    },
    descriptionTextContainer: {
        overflowY: 'auto',
        maxHeight: '200px',
    },
    descriptionText: {
        textWrap: 'wrap',
        fontFamily: 'Inter',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '20px',
        color: '#F2F2F2',
    },
    descriptionSkeleton: {
        borderRadius: '8px',
    },
    closeIcon: {
        height: '100%',
        width: '100%',
        color: 'white',
    },
    objectNameText: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        color: '#FFFFFF',
    },
    descriptionTitleText: {
        fontFamily: 'Inter',
        fontSize: '11px',
        fontWeight: 400,
        lineHeight: '21px',
        color: '#EBEBEB',
    },
    metadataFieldText: {
        fontFamily: 'Roboto',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '26px',
        color: '#EBEBEB',
    },
    evoViewerButtonText: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14px',
        color: '#FFFFFF',
        paddingLeft: '16px',
    },
    evoViewerButtonIcon: {
        width: '14px',
        height: '14px',
    },
}));

export const useToolTipStyles = makeStyles()(() => ({
    arrow: {
        color: '#d0d0d0',
    },
    tooltip: {
        maxWidth: '100%',
        fontSize: 16,
    },
}));

import {
    OrgListedObject,
    useEnhancedUpdateObjectByIdMutation,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { ListItem } from '@local/web-design-system-2/dist/components/GenericListing/ListItem';
import { Overflow } from '@local/web-design-system-2/dist/icons';
import FileRestoreIcon from '@local/web-design-system-2/dist/icons/FileRestore';
import {
    getHubForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import {
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Menu,
    MenuItem,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SerializedError } from '@reduxjs/toolkit';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
    RESTORE_DIALOG_CANCEL,
    RESTORE_DIALOG_CONFIRM,
    RESTORE_DIALOG_CONTENT,
    RESTORE_DIALOG_TITLE,
    RESTORE_DIALOG_WARNING,
    RESTORE_OBJECT_ACTION,
    RESTORE_TOAST_FAILURE,
    RESTORE_TOAST_SUCCESS,
    VIEW_RESTORED_OBJECT,
} from 'src/strings';
import { formatObjectName } from 'src/utils/objectUtils';

import { recycledObjectDefinition } from '../workspacePage/workspaceContent/FieldRowDefinitions';
import { useStyles } from '../workspacePage/workspaceContent/ObjectRow.styles';

const RestoreAction = () => (
    <Grid
        gap={2}
        container
        alignItems="center"
        justifyContent="flex-start"
        automation-id="object-row-restore-action"
    >
        <FileRestoreIcon />
        <Typography>{RESTORE_OBJECT_ACTION}</Typography>
    </Grid>
);

interface RecycleActionProps {
    onRestore: () => void;
    canRestore?: boolean;
}

const RecycledObjectActions = ({ onRestore, canRestore }: RecycleActionProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" item xs>
                <IconButton
                    disabled={!canRestore}
                    onClick={handleClick}
                    color="secondary"
                    automation-id="object-row-restore-button"
                >
                    <Overflow fontSize="small" />
                </IconButton>
            </Grid>
            <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
                <MenuItem onClick={onRestore} automation-id="menu-menu-item-restore-object">
                    <RestoreAction />
                </MenuItem>
            </Menu>
        </>
    );
};

interface RecycledObjectRowProps {
    object: OrgListedObject;
}

interface ExtendedError extends SerializedError {
    status?: number;
}

export const RecycledObjectRow = ({ object }: RecycledObjectRowProps) => {
    const params = useParams();
    const { classes } = useStyles();
    const { addMessage } = useMessagesContext();
    const [updateObjectsById] = useEnhancedUpdateObjectByIdMutation();
    const [restoreDialogToggle, setRestoreDialogToggle] = useState(false);

    const onRestore = async (confirm: boolean | null) => {
        if (confirm) {
            const response = await updateObjectsById({
                orgId: getOrgUuidFromParams(params),
                workspaceId: object.workspace_id,
                objectId: object.object_id,
                deleted: false,
                geoscienceObject: null,
            });
            const responseError = response?.error as ExtendedError;
            const status = responseError?.status;
            if (status === 303 || !responseError) {
                addMessage({
                    message: RESTORE_TOAST_SUCCESS,
                    severity: 'success',
                    inlineChildren: true,
                    action: (
                        <Grid container alignItems="center">
                            <Button
                                component={Link}
                                sx={{ whiteSpace: 'nowrap' }}
                                to={`/${getOrgUuidFromParams(
                                    params,
                                )}/workspaces/${getHubForCurrentOrg()}/${
                                    object.workspace_id
                                }/overview`}
                                className={classes.recycledToastButtonStart}
                            >
                                {VIEW_RESTORED_OBJECT}
                            </Button>
                        </Grid>
                    ),
                });
            } else {
                addMessage({
                    message: RESTORE_TOAST_FAILURE,
                    severity: 'error',
                });
            }
        }
        setRestoreDialogToggle(false);
    };

    interface ConfirmRestoreDialogProps {
        open: boolean;
        onConfirm: () => void;
        onCancel: () => void;
        onClose: () => void;
    }

    const ConfirmRestoreDialog = ({
        open,
        onConfirm,
        onCancel,
        onClose,
    }: ConfirmRestoreDialogProps) => (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle automation-id="dialog-title">{RESTORE_DIALOG_TITLE}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`${RESTORE_DIALOG_CONTENT} "${formatObjectName(object.name)}".`}
                    <br />
                    {`${RESTORE_DIALOG_WARNING} "${object.workspace_name}".`}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <Button onClick={onCancel} variant="outlined" automation-id="dialog-cancel-button">
                    {RESTORE_DIALOG_CANCEL}
                </Button>
                <Button
                    onClick={onConfirm}
                    variant="contained"
                    automation-id="dialog-confirm-button"
                >
                    {RESTORE_DIALOG_CONFIRM}
                </Button>
            </DialogActions>
        </Dialog>
    );

    const handleConfirm = () => {
        onRestore(true);
        setRestoreDialogToggle(false);
    };

    const handleCancel = () => {
        onRestore(false);
        setRestoreDialogToggle(false);
    };

    const handleClose = () => {
        setRestoreDialogToggle(false);
        handleCancel();
    };

    return (
        <>
            <ListItem
                item={object}
                key={object.object_id}
                fields={recycledObjectDefinition}
                actions={
                    <RecycledObjectActions
                        onRestore={() => setRestoreDialogToggle(true)}
                        canRestore={hasRoleOrHigher(object.workspace_access || null, 'editor')}
                    />
                }
            />

            <ConfirmRestoreDialog
                open={restoreDialogToggle}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                onClose={handleClose}
            />
        </>
    );
};

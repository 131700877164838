import {
    GetObjectResponse,
    OrgListedObject,
    useLazyGetObjectByIdQuery,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import Delete from '@local/web-design-system-2/dist/icons/Delete';
import Open from '@local/web-design-system-2/dist/icons/Open';
import Overflow from '@local/web-design-system-2/dist/icons/Overflow';
import { ListItemIcon, ListItemText, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    OPEN_IN_BLOCK_SYNC_ACTION,
    OPEN_IN_DRIVER_ACTION,
    OPEN_IN_VIEWER_ACTION,
    RECYCLE_OBJECT_ACTION,
} from 'src/strings';
import { isSchemaViewable } from 'src/utils/extractSchema';
import {
    canOpenInBlockSync,
    canOpenInDriver,
    canOpenSchemaInBlockSync,
    createNavigateToBlockSync,
    createNavigateToDriver,
    createNavigateToViewer,
} from 'src/utils/viewObjectUtils';

import useStyles from './ObjectActions.styles';
import { ListedObjectDisplay } from './utils';

interface ObjectActionProps {
    object: OrgListedObject | ListedObjectDisplay;
    onRecycle: () => void;
}

interface MenuItemConfig {
    key: string | number;
    ItemComponent: React.ComponentType<any>;
    action: () => void;
}

interface DynamicMenuItemConfig extends MenuItemConfig {
    // Whether or not the option itself should be eventually rendered
    shouldRender: boolean;
    // Whether or not we show a skeleton for this option while loading.
    // Used in conjunction with shouldRender to determine if a fetch is needed.
    // If an option should show a skeleton but should not currently be rendered it means a data fetch is needed.
    showSkeleton: boolean;
}

function RecycleAction() {
    return (
        <Grid container alignItems="center" automation-id="object-row-delete-action">
            <ListItemIcon>
                <Delete />
            </ListItemIcon>
            <ListItemText>{RECYCLE_OBJECT_ACTION}</ListItemText>
        </Grid>
    );
}

function OpenInDriverAction() {
    // TODO Add Driver Icon
    return (
        <Grid container alignItems="center" automation-id="object-row-open-in-driver-action">
            <ListItemIcon>
                <Open />
            </ListItemIcon>
            <ListItemText>{OPEN_IN_DRIVER_ACTION}</ListItemText>
        </Grid>
    );
}

function OpenInViewerAction() {
    // TODO Add Viewer Icon
    return (
        <Grid container alignItems="center" automation-id="object-row-open-in-viewer-action">
            <ListItemIcon>
                <Open />
            </ListItemIcon>
            <ListItemText>{OPEN_IN_VIEWER_ACTION}</ListItemText>
        </Grid>
    );
}

function OpenInBlockSyncAction() {
    return (
        <Grid container alignItems="center" automation-id="object-row-open-in-blocksync-action">
            <ListItemIcon>
                <Open />
            </ListItemIcon>
            <ListItemText>{OPEN_IN_BLOCK_SYNC_ACTION}</ListItemText>
        </Grid>
    );
}

function SkeletonMenuOption() {
    const { classes } = useStyles();
    // Wrap Recycle action to allow skeleton to infer dimensions
    return (
        <Grid
            className={classes.objectActionGrid}
            automation-id="object-row-open-in-skeleton-action"
        >
            <Skeleton animation="wave" variant="rounded">
                <RecycleAction />
            </Skeleton>
        </Grid>
    );
}

export function ObjectActions({ object, onRecycle }: ObjectActionProps) {
    const theme = useTheme();
    const featureFlags = useFlags();
    const { evouiEnableDelete } = featureFlags;
    const { hostname } = window.location;
    const { orgUuid, workspaceUuid, hubCode } = useParams();
    const navigate = useNavigate();
    const [objectDetail, setObjectDetail] = useState<GetObjectResponse | undefined>(undefined);
    const [getObjectByIdTrigger] = useLazyGetObjectByIdQuery();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        handleMenuOpen();
    };

    async function handleMenuOpen() {
        if (!orgUuid || !workspaceUuid || !shouldFetch) {
            return;
        }

        const gooseResponse = await getObjectByIdTrigger(
            {
                objectId: object.object_id,
                orgId: orgUuid,
                workspaceId: workspaceUuid,
            },
            true, // Cache query.
        ).unwrap();
        setObjectDetail(gooseResponse);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRecycle = () => {
        handleClose();
        onRecycle();
    };

    if (!orgUuid || !workspaceUuid || !hubCode) {
        return null;
    }

    // Options are split into pre and post divider options to allow for a dynamic divider to appear between the options
    // depending on which options are rendered for a given object.
    const preDividerOptions: DynamicMenuItemConfig[] = [
        {
            key: 'openInViewer',
            action: createNavigateToViewer(navigate, hubCode, workspaceUuid, object.object_id),
            ItemComponent: OpenInViewerAction,
            shouldRender: isSchemaViewable(object.schema, featureFlags),
            showSkeleton: isSchemaViewable(object.schema, featureFlags),
        },
        {
            key: 'openInBlockSync',
            action: objectDetail
                ? createNavigateToBlockSync(
                      hostname,
                      orgUuid,
                      workspaceUuid,
                      objectDetail.object.blocksync_uuid,
                  )
                : () => {},
            ItemComponent: OpenInBlockSyncAction,
            shouldRender: canOpenInBlockSync(objectDetail, featureFlags),
            showSkeleton: canOpenSchemaInBlockSync(object.schema, featureFlags),
        },
        {
            key: 'openInDriver',
            action: createNavigateToDriver(
                hostname,
                orgUuid,
                hubCode,
                workspaceUuid,
                object.object_id,
            ),
            ItemComponent: OpenInDriverAction,
            shouldRender: canOpenInDriver(object.schema, featureFlags),
            showSkeleton: canOpenInDriver(object.schema, featureFlags),
        },
    ];
    const postDividerOptions: DynamicMenuItemConfig[] = [
        {
            key: 'recycle',
            action: handleRecycle,
            ItemComponent: RecycleAction,
            shouldRender: evouiEnableDelete,
            showSkeleton: evouiEnableDelete,
        },
    ];

    // Calculate the number of skeleton options based on showSkeleton
    const skeletonCount = [...preDividerOptions, ...postDividerOptions].filter(
        (option) => option.showSkeleton,
    ).length;
    const skeletonOptions: MenuItemConfig[] = Array.from({ length: skeletonCount }, (_, index) => ({
        key: `skeleton-${index}`,
        action: () => {},
        ItemComponent: SkeletonMenuOption,
    }));

    const optionsToRender = preDividerOptions
        .filter((option) => option.shouldRender)
        .concat(postDividerOptions.filter((option) => option.shouldRender));

    // If all skeleton objects are already set to be rendered there is no need to load a detail response to render specific options.
    const shouldFetch = skeletonCount !== optionsToRender.length;

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" item xs>
                <IconButton
                    size="large"
                    onClick={handleClick}
                    automation-id="overflow-icon"
                    sx={{ color: theme.palette.text.secondary }}
                >
                    <Overflow fontSize="small" />
                </IconButton>
            </Grid>
            <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
                {!(objectDetail || !shouldFetch) &&
                    skeletonOptions.map((option) => (
                        <MenuItem key={option.key}>
                            <SkeletonMenuOption />
                        </MenuItem>
                    ))}
                {(objectDetail || !shouldFetch) &&
                    [...preDividerOptions].map((option) => {
                        const { key, ItemComponent, action, shouldRender } = option;
                        if (!shouldRender) {
                            return null;
                        }
                        return (
                            <MenuItem key={key} onClick={() => action()}>
                                <ItemComponent />
                            </MenuItem>
                        );
                    })}
                {(objectDetail || !shouldFetch) &&
                    [...postDividerOptions].map((option) => {
                        const { key, ItemComponent, action, shouldRender } = option;
                        if (!shouldRender) {
                            return null;
                        }
                        return (
                            <MenuItem key={key} onClick={() => action()}>
                                <ItemComponent />
                            </MenuItem>
                        );
                    })}
            </Menu>
        </>
    );
}

import {
    Breadcrumbs,
    Link,
    useTheme,
    Skeleton,
    Grid,
    Typography,
    Stack,
    SkeletonProps,
} from '@mui/material';
import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { FILES, GEOSCIENCE_OBJECTS, RECYCLE_BIN_TITLE } from 'src/strings';

const PATH_TITLE_MAP: { [key: string]: string } = {
    files: FILES,
    overview: GEOSCIENCE_OBJECTS,
    recyclebin: RECYCLE_BIN_TITLE,
};

function getPageTitle(pathname: string) {
    const segment = pathname.slice(pathname.lastIndexOf('/') + 1, pathname.length);
    return PATH_TITLE_MAP[segment] || segment;
}

interface SkeletonContentProps extends React.PropsWithChildren<SkeletonProps> {
    loading: boolean;
}

function SkeletonContent({ children, loading, ...skelton }: SkeletonContentProps) {
    if (loading) {
        return <Skeleton {...skelton} />;
    }
    return children;
}

export interface PageContentProps {
    breadcrumbSegments: { name: string; path?: string }[];
    breadcrumbsLoading?: boolean;
    pageTitle: string;
    pageTitleLoading?: boolean;
    titleActions?: JSX.Element;
    children: React.ReactNode;
    childrenLoading?: boolean;
    childrenLoadingContent?: JSX.Element;
    enablePageTitle?: boolean;
}

export const PageContent = ({
    breadcrumbSegments,
    breadcrumbsLoading = false,
    children,
    pageTitle,
    pageTitleLoading = false,
    titleActions,
    childrenLoading = false,
    childrenLoadingContent,
    enablePageTitle = true,
}: PageContentProps) => {
    const theme = useTheme();
    const location = useLocation();

    const segments = breadcrumbSegments.map(({ name, path }) => (
        <Link
            key={`${name}-${path}`}
            component={RouterLink}
            to={path ?? ''}
            sx={{
                textDecoration: 'none',
                color: theme.palette.grey[700],
                fontSize: 12,
                lineHeight: '14px',
                letterSpacing: '0.13px',
            }}
        >
            {name}
        </Link>
    ));

    return (
        <>
            <div style={{ position: 'absolute' }}>
                <SkeletonContent
                    width="200px"
                    height="14px"
                    variant="rectangular"
                    loading={breadcrumbsLoading}
                >
                    <Breadcrumbs sx={{ flexBasis: 0 }}>{segments}</Breadcrumbs>
                </SkeletonContent>
            </div>
            <Stack direction="row" sx={{ height: '140px' }} alignItems="center">
                <Stack direction="column" flexGrow={1} position="relative">
                    <SkeletonContent
                        width="100%"
                        height="24px"
                        variant="rectangular"
                        loading={pageTitleLoading}
                    >
                        <Typography variant="h3" automation-id="title-row">
                            {pageTitle}
                        </Typography>
                    </SkeletonContent>
                    <Typography
                        variant="h5"
                        sx={{ textTransform: 'capitalize', position: 'absolute', top: '38px' }}
                    >
                        {enablePageTitle && getPageTitle(location.pathname)}
                    </Typography>
                </Stack>
                <Grid item>{titleActions}</Grid>
            </Stack>
            <Grid container direction="column">
                {childrenLoading && childrenLoadingContent ? childrenLoadingContent : children}
            </Grid>
        </>
    );
};

import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    backButton: {
        paddingBottom: theme.spacing(),
    },
    formColumn: {
        borderRight: `1px solid ${theme.palette.grey[200]}`,
        [theme.breakpoints.up('lg')]: {
            height: '100%',
        },
    },
    headerRow: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(),
        maxHeight: theme.spacing(5),
        minHeight: theme.spacing(5),
    },
    permissionContainer: {
        height: '100%',
    },
    contentRow: {
        flexBasis: 'auto',
        height: '100%',
    },
}));

import { DateRange } from '@local/web-design-system/dist/components/DateRangeCalendar/types';
import { useEffect, useState } from 'react';

import { useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import { filterTypeMetaData } from 'src/mockApi/mockFilterDataTypes';

import { usePersistedState } from './usePersistedState';

enum FilterType {
    TYPE = 'type',
    UPDATED_BY = 'updatedBy',
    UPDATED_ON_START = 'updateOnStart',
    UPDATED_ON_END = 'updatedOnEnd',
}

/**
 * Provides a custom hook for the object list filter parameters.
 * Applied filter parameters are stored in the url as query parameters.
 * Also stores the temporary state of the filter menu.
 */
export function useObjectFilterParams() {
    const { objectFilters, setObjectFilters } = useWorkspaceContext();
    const persistedStates = Object.fromEntries(
        Object.values(FilterType).map((key) => {
            // While you should never call Hooks inside a loop, always the top level,
            // it is technically okay to do this, as long as the order of values in the loop never change.
            // Given iterating over an enum with Object.values() will maintain order, the following is safe.
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const [value, setValue] = usePersistedState(key);
            return [key, { value, setValue }];
        }),
    );

    const { value: typeParamString } = persistedStates[FilterType.TYPE];
    const { value: updatedByParamString } = persistedStates[FilterType.UPDATED_BY];
    const { value: updatedOnStartParamString } = persistedStates[FilterType.UPDATED_ON_START];
    const { value: updatedOnEndParamString } = persistedStates[FilterType.UPDATED_ON_END];

    const [typeParams, setTypeParams] = useState<string[]>([]);
    const [updatedByParams, setUpdatedByParams] = useState<string[]>([]);
    const [updatedOnParams, setUpdatedOnParams] = useState<DateRange>({});

    useEffect(() => {
        setTypeParams(typeParamString === '' ? [] : typeParamString?.split(',') ?? []);
    }, [typeParamString]);

    useEffect(() => {
        setUpdatedByParams(
            updatedByParamString === '' ? [] : updatedByParamString?.split(',') ?? [],
        );
    }, [updatedByParamString]);

    useEffect(() => {
        if (updatedOnStartParamString && updatedOnEndParamString) {
            setUpdatedOnParams({
                startDate: new Date(updatedOnStartParamString),
                endDate: new Date(updatedOnEndParamString),
            });
        } else {
            setUpdatedOnParams({});
        }
    }, [updatedOnStartParamString, updatedOnEndParamString]);

    const [type, setType] = useState<string[]>(typeParams);
    const [updatedBy, setUpdatedBy] = useState<string[]>(updatedByParams);
    const [updatedOn, setUpdatedOn] = useState<DateRange>(updatedOnParams);

    const reset = () => {
        setType(typeParams);
        setUpdatedBy(updatedByParams);
        setUpdatedOn(updatedOnParams);
    };

    const applyFilters = () => {
        const newFilters: any = {
            [FilterType.TYPE]: type.length === 0 ? undefined : type.join(','),
            [FilterType.UPDATED_BY]: updatedBy.length === 0 ? undefined : updatedBy.join(','),
        };

        if (updatedOn.startDate && updatedOn.endDate) {
            newFilters[FilterType.UPDATED_ON_START] = updatedOn.startDate.toISOString();
            newFilters[FilterType.UPDATED_ON_END] = updatedOn.endDate.toISOString();
        } else if (!updatedOn.startDate && !updatedOn.endDate) {
            newFilters[FilterType.UPDATED_ON_START] = undefined;
            newFilters[FilterType.UPDATED_ON_END] = undefined;
        }
        setObjectFilters({ ...objectFilters, ...newFilters });
    };

    const clearAllParams = () => {
        setType([]);
        setUpdatedBy([]);
        setUpdatedOn({});
        setObjectFilters(
            Object.fromEntries(Object.values(FilterType).map((key) => [key, undefined])),
        );
    };

    const removeTypeParam = (typeParam: string) => {
        setObjectFilters({
            ...objectFilters,
            [FilterType.TYPE]:
                typeParams.length <= 1
                    ? undefined
                    : typeParams.filter((param) => param !== typeParam).join(','),
        });
    };

    const removeUpdatedByParam = (updatedByParam: string) => {
        setObjectFilters({
            ...objectFilters,
            [FilterType.UPDATED_BY]:
                updatedByParams.length <= 1
                    ? undefined
                    : updatedByParams.filter((param) => param !== updatedByParam).join(','),
        });
    };

    const removeUpdatedOnParam = () => {
        setObjectFilters({
            ...objectFilters,
            [FilterType.UPDATED_ON_END]: undefined,
            [FilterType.UPDATED_ON_START]: undefined,
        });
    };

    const cannotApplyFilters =
        typeParams.length === type.length &&
        typeParams.every((param) => type.includes(param)) &&
        updatedByParams.length === updatedBy.length &&
        updatedByParams.every((param) => updatedBy.includes(param)) &&
        updatedOnParams.startDate?.getTime() === updatedOn.startDate?.getTime() &&
        updatedOnParams.endDate?.getTime() === updatedOn.endDate?.getTime();

    const cannotClearFilters =
        type.length === 0 &&
        typeParams.length === 0 &&
        updatedBy.length === 0 &&
        updatedByParams.length === 0 &&
        !updatedOn.startDate &&
        !updatedOn.endDate &&
        !updatedOnParams.startDate &&
        !updatedOnParams.endDate;

    const nextDay = (date: Date) => {
        const newDate = new Date(date);
        newDate.setDate(date.getDate() + 1);
        return newDate;
    };

    const [filters, setFilters] = useState<Record<string, any>>({});
    useEffect(() => {
        setFilters({
            schemaId:
                typeParams.length > 0
                    ? typeParams
                          .flatMap(
                              (param) =>
                                  filterTypeMetaData.options.find(
                                      (option) => option.description === param,
                                  )?.comparison || [],
                          )
                          .map((param) => `like:*${param}*`)
                    : undefined,
            author: updatedByParams.length > 0 ? updatedByParams : undefined,
            createdAt:
                updatedOnParams.startDate && updatedOnParams.endDate
                    ? [
                          `gte:${updatedOnParams.startDate.toISOString()}`,
                          `lte:${nextDay(updatedOnParams.endDate).toISOString()}`,
                      ]
                    : undefined,
        });
    }, [typeParams, updatedByParams, updatedOnParams]);

    useEffect(() => {
        Object.entries(objectFilters).forEach(([key, value]) => {
            if (key in persistedStates) {
                persistedStates[key as FilterType].setValue(value);
            }
        });
    }, [objectFilters]);

    return {
        typeParams,
        updatedByParams,
        updatedOnParams,
        removeTypeParam,
        removeUpdatedByParam,
        removeUpdatedOnParam,
        clearAllParams,
        type,
        setType,
        updatedBy,
        setUpdatedBy,
        updatedOn,
        setUpdatedOn,
        applyFilters,
        cannotApplyFilters,
        cannotClearFilters,
        reset,
        filters,
    };
}

import type { GetObjectResponse } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { extractObjectSchemaName } from '@local/api-clients/dist/utils/extractObjectSchemaName';
import type { Folder } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import { Box, Divider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ObjectViewOptionsMenu } from 'src/components/sidebar/ObjectViewOptionsMenu';
import { OpenInViewerButton } from 'src/components/sidebar/OpenInViewerButton';
import { PropertyDate } from 'src/components/sidebar/PropertyDate';
import { FilterProperties } from 'src/pages/workspacePage/workspaceContent/sidebar/FilterProperties';
import {
    CREATED_BY,
    CREATED_ON,
    DATA_TYPE,
    OBJECT_ID,
    OBJECT_PATH,
    PROPERTIES,
    SOURCE,
    VERSION_ID,
} from 'src/strings';
import { hasMultipleOpenOptions } from 'src/utils/viewObjectUtils';

import { Icon } from './Icon';

export function NameValueItem({ name, children }: React.PropsWithChildren<{ name: string }>) {
    return (
        <>
            <Typography variant="body2" noWrap>
                {name}:
            </Typography>
            {children}
        </>
    );
}

interface Props {
    isFile: boolean;
    data?: GetObjectResponse;
    selectedFilter?: Folder;
}

export const Properties = ({ isFile, data, selectedFilter }: Props) => {
    const featureFlags = useFlags();

    if (!isFile) {
        // Show filter properties
        return <FilterProperties data={selectedFilter} />;
    }

    if (!data) {
        return null;
    }

    const showOpenButtonIcon = !hasMultipleOpenOptions(data, featureFlags);
    // Only have the viewer button be primary if other options are enabled.
    const viewerButtonVariant =
        featureFlags.evouiOpenInDriver || featureFlags.evouiOpenInBlocksync
            ? 'primary'
            : 'secondary';

    return (
        <Stack spacing={2}>
            <Typography variant="body1">{PROPERTIES}</Typography>
            <Divider />
            <Stack alignItems="center" direction="row" spacing={1}>
                <Icon schema={data.object.schema} />
                <Typography
                    automation-id="object-name"
                    variant="subtitle1"
                    title={data.object.name}
                >
                    {data.object.name}
                </Typography>
            </Stack>
            <Stack sx={{ paddingBottom: '16px' }}>
                <Box
                    sx={{
                        rowGap: '4px',
                        columnGap: '8px',
                        wordWrap: 'break-word',
                        display: 'inline-grid',
                        wordBreak: 'break-all',
                        overflowWrap: 'break-word',
                        gridTemplateColumns: 'min-content auto',
                    }}
                >
                    <NameValueItem name={DATA_TYPE}>
                        <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                            {extractObjectSchemaName(data.object.schema)}
                        </Typography>
                    </NameValueItem>
                    <NameValueItem name={OBJECT_PATH}>
                        <Typography variant="body2">{data.object_path}</Typography>
                    </NameValueItem>
                    <NameValueItem name={SOURCE}>
                        <Typography variant="body2">{data.object.source}</Typography>
                    </NameValueItem>
                    <NameValueItem name={VERSION_ID}>
                        <Typography variant="body2">{data.version_id}</Typography>
                    </NameValueItem>
                    <NameValueItem name={OBJECT_ID}>
                        <Typography variant="body2">{data?.object_id}</Typography>
                    </NameValueItem>
                    <NameValueItem name={CREATED_ON}>
                        <PropertyDate timestamp={data.created_at} />
                    </NameValueItem>
                    <NameValueItem name={CREATED_BY}>
                        <Typography variant="body2">{data.created_by?.name}</Typography>
                    </NameValueItem>
                    {...data.object.tags
                        ? Object.keys(data.object.tags).map((key) => (
                              <NameValueItem name={key}>
                                  <Typography variant="body2">{data.object.tags[key]}</Typography>
                              </NameValueItem>
                          ))
                        : []}
                </Box>
            </Stack>
            <OpenInViewerButton
                objectSchema={data.object.schema}
                variant={viewerButtonVariant}
                showIcon={showOpenButtonIcon}
            />
            <ObjectViewOptionsMenu
                objectDetail={data}
                variant="secondary"
                showIcon={showOpenButtonIcon}
            />
        </Stack>
    );
};

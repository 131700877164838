import { OpenIcon } from '@local/web-design-system';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate, useParams } from 'react-router-dom';

import { usePersistedState } from 'src/hooks/usePersistedState';
import { ID_PARAM, WEBVIZ_BUTTON } from 'src/strings';
import { isSchemaViewable } from 'src/utils/extractSchema';
import { createNavigateToViewer } from 'src/utils/viewObjectUtils';

interface OpenInViewerButtonProps {
    readonly objectSchema: string;
    readonly variant?: 'primary' | 'secondary';
    readonly showIcon: boolean;
}

export function OpenInViewerButton({
    objectSchema,
    variant = 'primary',
    showIcon = true,
}: OpenInViewerButtonProps) {
    const featureFlags = useFlags();
    const { workspaceUuid, hubCode } = useParams();
    const [id] = usePersistedState(ID_PARAM);
    const navigate = useNavigate();

    if (!isSchemaViewable(objectSchema, featureFlags) || !id || !workspaceUuid || !hubCode) {
        return null;
    }

    return (
        <Button
            automation-id="go-to-plot-buttons-view"
            size="large"
            onClick={() => {
                createNavigateToViewer(navigate, hubCode, workspaceUuid, id)();
            }}
            variant="contained"
            color={variant}
            sx={(theme) => ({
                flex: '1 1',
                '&.Mui-disabled': {
                    pointerEvents: 'auto',
                },
                marginTop: theme.spacing(2),
                height: '48px',
                width: '100%',
            })}
        >
            {showIcon && <OpenIcon sx={{ marginRight: '16px' }} />}
            <Typography variant="body2" sx={{ fontSize: '13px' }}>
                {WEBVIZ_BUTTON}
            </Typography>
        </Button>
    );
}

import { ObjectPropertiesEmpty } from '@local/svgs/dist/svg/ObjectPropertiesEmpty';
import { Divider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import { NO_SELECTED_OBJECT, PROPERTIES } from 'src/strings';

export function Empty() {
    return (
        <Stack justifyContent="center" spacing={1}>
            <Typography variant="body1" gutterBottom>
                {PROPERTIES}
            </Typography>
            <Divider />
            <Stack alignItems="center" paddingTop={3} spacing={2}>
                <ObjectPropertiesEmpty />
                <Typography variant="body2">{NO_SELECTED_OBJECT}</Typography>
            </Stack>
        </Stack>
    );
}

import { getConfig } from '@local/app-config';
import { ActionButton } from '@local/content-area/dist/TitleRow/buttons/ActionButton';
import { getAdminFromBentleyAccessToken } from '@local/login';
import { BentleyGenericUserList } from '@local/user-manage/dist/components/GenericUserList/BentleyGenericUserList';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useNavigate } from 'react-router-dom';

import { PageContent } from 'src/components/pageContent/PageContent';
import { usersBreadcrumb } from 'src/utils/breadcrumbs';

import { MANAGE_USERS, ADD_USER_TOOLTIP, ADMINISTRATORS_TITLE } from '../../strings';

function InviteUsersButton() {
    // Don't show button if not a Bentley Account admin
    if (!getAdminFromBentleyAccessToken()) {
        return <div />;
    }

    // TODO: Replace Icon with WDS2 Icon

    return (
        <ActionButton
            text={MANAGE_USERS}
            tooltipTitle={ADD_USER_TOOLTIP}
            onClick={() => {
                window.open(`${getConfig().bentleyUserManagementUrl}/management/users`, '_blank');
            }}
        >
            <PersonAddAltIcon fontSize="small" />
        </ActionButton>
    );
}

export function ListUsersPage() {
    setDocumentTitle('Users');
    const navigate = useNavigate();

    const selectUser = (userUuid: string) => {
        navigate(`${userUuid}`);
    };

    return (
        <PageContent
            breadcrumbSegments={[usersBreadcrumb]}
            pageTitle={ADMINISTRATORS_TITLE}
            titleActions={<InviteUsersButton />}
        >
            <BentleyGenericUserList service="evo" onClickRow={selectUser} addMessages />
        </PageContent>
    );
}

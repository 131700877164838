import { checkIsAdmin } from '@local/login';
import { useTrace } from '@local/web-design-system/dist/utils';
import { fetchWorkSpaceData } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import { isDisabled } from '@local/workspaces/dist/components/WorkspaceTabs/RoutedTabs';
import { RoutedTabsProps } from '@local/workspaces/dist/components/WorkspaceTabs/RoutedTabs.types';
import { RESTRICTED_TAB } from '@local/workspaces/dist/strings';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Custom version of RoutedTabs, forked from the workspace library.
export function RoutedTabs({ tabDefinitions }: RoutedTabsProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const applyTrace = useTrace('routed-tab');
    const isAdmin = checkIsAdmin();
    const params = useParams();

    const { data, isError } = fetchWorkSpaceData(params, isAdmin);
    const userRole = data?.current_user_role;
    const currentTab = tabDefinitions.find((tabDefinition) =>
        location.pathname.endsWith(tabDefinition.path),
    );

    return (
        <Tabs
            onChange={() => {}}
            textColor="primary"
            indicatorColor="primary"
            value={currentTab?.path || ''}
            sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
            {tabDefinitions.map(({ key, label, path }: any) => (
                <Tab
                    style={{ pointerEvents: 'auto' }}
                    sx={{ textTransform: 'capitalize' }}
                    key={path}
                    label={
                        <Tooltip
                            placement="top"
                            title={
                                !isError && isDisabled(path, userRole, isAdmin)
                                    ? RESTRICTED_TAB
                                    : ''
                            }
                        >
                            <span>{label}</span>
                        </Tooltip>
                    }
                    value={path}
                    onClick={() => navigate(`../${path}`)}
                    {...applyTrace(key)}
                    disabled={!isError ? isDisabled(path, userRole, isAdmin) : false}
                />
            ))}
        </Tabs>
    );
}
